/* eslint-disable */
import canUseDOM from './canUseDOM';

var fallback = function fallback(context, node) {
  if (node) {
    do {
      if (node === context) {
        return true;
      }
    } while (node = node.parentNode);
  }

  return false;
}; // HTML DOM and SVG DOM may have different support levels,
// so we need to check on context instead of a document root element.


var contains = function contains(context, node) {
  if (context.contains) {
    return context.contains(node);
  } else if (context.compareDocumentPosition) {
    return context === node || !!(context.compareDocumentPosition(node) & 16);
  }

  return fallback(context, node);
};

export default (function () {
  return canUseDOM ? contains : fallback;
})();